@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@font-face {
  font-family: "Gilroy-Bold-☞";
  src: url(./gilroy/Gilroy-Bold.ttf);
}
@font-face {
  font-family: Quicksand;
  src: url(./Quicksand-VariableFont_wght.ttf);
}
@font-face {
  font-family: Gilroy-Bold;
  src: url(./gilroy/Gilroy-Bold.ttf);
}
@font-face {
  font-family: Gilroy;
  src: url(./gilroy/Gilroy-Medium.ttf);
}
@font-face {
  font-family: Gilroy-Light;
  src: url(./gilroy/Gilroy-Regular.ttf);
}
.mylink {
  color: white !important;
  border: 2px solid black !important;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Quicksand, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.continer-fluid {
  overflow: hidden;
}
a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}
