@import "./media.scss";
.first_slider {
  position: relative;
}
.first_slider #owl-carousel-ul {
  position: static !important;
}
.first_slider .owl-nav {
  position: absolute;
  right: -20px;
  top: -150px;
  gap: 50px !important;
  @include xtablet {
    bottom: -90px !important;
    top: auto !important;
    left: 0px !important;
    right: 0px !important;
  }
}
.first_slider .owl-prev,
.first_slider .owl-next {
  width: 55px !important;
  height: 55px !important;
  border: 1px solid black !important;
  border-radius: 50% !important;
}
#owl-carousel-ul {
  @include xtablet {
    padding-left: 0px !important;
  }
}
// Our Work

.our_work {
  position: relative;
}
.our_work #owl-carousel-ul {
  position: static !important;
}
.our_work .owl-nav {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 40%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  @include xtablet {
    bottom: -90px !important;
    top: auto !important;
    left: 0px !important;
    right: 0px !important;
    display: block !important;
  }
}
.our_work .owl-prev,
.our_work .owl-next {
  width: 55px !important;
  height: 55px !important;
  border: 1px solid black !important;
  border-radius: 50% !important;
}
.our_work .owl-prev {
  margin-left: -70px !important;
}
.our_work .owl-next {
  margin-right: -70px !important;
}
.our_work .social_icon {
  width: 34px !important;
  height: 34px !important;
  object-fit: cover !important;
  padding: 0px !important;
}

// Feature Listing

.feature_listing {
  position: relative;
}
.feature_listing #owl-carousel-ul {
  position: static !important;
}
.feature_listing .owl-nav {
  position: absolute;
  right: 40px !important;
  top: -150px;
  left: auto !important;
  gap: 50px !important;
  display: block !important;
  width: fit-content !important;
  @include xtablet {
    bottom: -90px !important;
    top: auto !important;
    left: 50% !important;
    right: 0px !important;
    display: block !important;
  }
}
.feature_listing .owl-prev,
.feature_listing .owl-next {
  width: 55px !important;
  height: 55px !important;
  border: 1px solid black !important;
  border-radius: 50% !important;
}

// Creative Potential

.creative_potential {
  position: relative;
}
.creative_potential #owl-carousel-ul {
  position: static !important;
}
.creative_potential .owl-nav {
  position: absolute;
  right: 40px !important;
  top: -150px;
  left: auto !important;
  gap: 50px !important;
  display: block !important;
  width: fit-content !important;
  @include xtablet {
    bottom: -90px !important;
    top: auto !important;
    left: 27% !important;
    right: auto !important;
    display: block !important;
  }
}
.creative_potential .owl-prev,
.creative_potential .owl-next {
  width: 55px !important;
  height: 55px !important;
  border: 1px solid black !important;
  border-radius: 50% !important;
}

// Navbar
.navbar {
  position: relative;
}
.navbar #owl-carousel-ul {
  position: static !important;
}
.navbar .owl-nav {
  position: absolute;
  right: 0px;
  left: 0px;
  top: -10px;
  bottom: 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  @include xtablet {
    bottom: 0px !important;
    top: -30px !important;
    left: 0px !important;
    right: 0px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}
.navbar .owl-prev,
.navbar .owl-next {
  width: 15px !important;
  height: 15px !important;
  border: 0px solid black !important;
  border-radius: 50% !important;
}
.navbar .owl-prev {
  margin-left: -70px !important;
  @include xtablet {
    margin-left: 0px !important;
    display: none !important;
  }
}
.navbar .owl-next {
  margin-right: -70px !important;
  @include xtablet {
    margin-right: 0px !important;
    display: none !important;
  }
}

.navbar .social_icon {
  width: 34px !important;
  height: 34px !important;
  object-fit: cover !important;
  padding: 0px !important;
}

// Makeup

.beauty_makeup {
  position: relative;
}
.beauty_makeup #owl-carousel-ul {
  position: static !important;
}
.beauty_makeup .owl-nav {
  position: absolute;
  right: 40px !important;
  top: -150px;
  left: auto !important;
  gap: 50px !important;
  display: block !important;
  width: fit-content !important;
  @include xtablet {
    bottom: -90px !important;
    top: auto !important;
    left: 28% !important;
    right: auto !important;
    display: block !important;
  }
}
.beauty_makeup .owl-prev,
.beauty_makeup .owl-next {
  width: 55px !important;
  height: 55px !important;
  border: 1px solid black !important;
  border-radius: 50% !important;
}

// Top Trends

.top_trending {
  position: relative;
}
.top_trending #owl-carousel-ul {
  position: static !important;
}
.top_trending .owl-nav {
  position: absolute;
  right: 40px !important;
  top: -150px;
  left: auto !important;
  gap: 50px !important;
  display: block !important;
  width: fit-content !important;
  @include xtablet {
    bottom: -90px !important;
    top: auto !important;
    left: 28% !important;
    right: auto !important;
    display: block !important;
  }
}
.top_trending .owl-prev,
.top_trending .owl-next {
  width: 55px !important;
  height: 55px !important;
  border: 1px solid black !important;
  border-radius: 50% !important;
}
a {
  text-decoration: none !important;
  // color: white !important;
}
// .signup_btn {
//   color: #68c1b8 !important;
// }
.continer-fluid .signup_btn a {
  color: #68c1b8 !important;
}
.listing_con .signup_btn a {
  color: #fff !important;
}
// .continer-fluid .influencers_btn a {
//   color: white !important;
// }

.top_page .header_signup a {
  color: white !important;
}

.continer-fluid .header_links_btn {
  color: white !important;
}
.continer-fluid .header_links_btn a {
  color: #fff !important;

  font-family: Quicksand !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}
.influencers_signup_con .header_links_btn a {
  color: #fff !important;
  font-family: Quicksand !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}

.brands_main_con .header_links_btn a {
  color: #fff !important;
  font-family: Quicksand !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}
.listing_con .header_links_btn a {
  color: #666;
  font-family: Quicksand !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}
.listing_con .header_links_btn {
  color: #666;
  font-family: Quicksand !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}

.brands .header_links_btn a {
  color: #fff !important;
  font-family: Quicksand !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
}
// .top_page .header_links_btn {
//   color: #fff !important;
//   font-family: Quicksand !important;
//   font-size: 16px !important;
//   font-style: normal !important;
//   font-weight: 700 !important;
//   line-height: 150% !important;
// }
.top_page .header_links_btn a{
  color: #fff !important;
  font-family: Quicksand !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 150% !important;
  
}